const websiteUrlMap = {
  map: 'https://map.meshstream.io/',
  service: 'https://map.meshstream.io/service',
  'p2p-meshstream': 'https://mellifluous-selkie-322aa7.netlify.app/',
  'conference-random': [
    { name: 'tw2', url: 'https://conference-tw2.meshstream.io/' },
    { name: 'office', url: 'https://conference-office.meshstream.io/' }
  ],
  'conference-cloud': 'https://conference.meshstream.io/',
  'conference-tw2': 'https://conference-tw2.meshstream.io/',
  'conference-office': 'https://conference-office.meshstream.io/',
  'conference-closest': 'https://conference-tw2.meshstream.io/',
  transcoder: 'https://transcoder.meshstream.io/',
  ipfs: 'https://ipfs-webui.meshstream.io',
  'tesl-cloud': 'https://tesl-tmp-staging.meshstream.io/',
  'tesl-staging': 'https://tesl-staging.meshstream.io/',
  'tesl-dev': 'https://tesl-test.meshstream.io/',
  'tesl-dev-en': 'https://tesl-test-en.meshstream.io/',
  'tesl-tw2-en': 'https://tesl-en-tw2.meshstream.io/'
};

export default websiteUrlMap;
