import { Icon as IconifyIcon } from '@iconify/react/offline';

import './Icon.scss';

function Icon(props) {
  return (
    <span className="anticon anticon-minus">
      <IconifyIcon inline height="20" {...props} />
    </span>
  );
}

export default Icon;
