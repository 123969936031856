import './BaseLink.scss';

const BaseLink = ({ title, url }) => {
  return (
    <a className="link" href={url} target="_blank" rel="noreferrer">
      {title}
    </a>
  );
};

export default BaseLink;
