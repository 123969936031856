import BaseLink from '../../components/BaseLink/BaseLink';

import './V1Home.scss';

const V1Home = () => {
  return (
    <div className="home">
      <main className="links">
        <section>
          <BaseLink
            title="Mesh Streaming"
            url="https://kohee-live.meshstream.io/"
          />
          <BaseLink
            title="Live Events - Meet Taipei"
            url="https://meettaipei-live.meshstream.io/"
          />
          <BaseLink
            title="Live Events - HLS vs RTC"
            url="https://live-streaming.meshstream.io/"
          />
          <BaseLink
            title="Live Events - Meshub Dashboard"
            url="https://live-event.meshstream.io/"
          />
          <BaseLink
            title="Investor World - RTMP to RTC"
            url="https://investor-world.meshstream.io/"
          />
          <BaseLink
            title="P2P Meshstream"
            url="https://mellifluous-selkie-322aa7.netlify.app"
          />
          <BaseLink
            title="TESL - Staging"
            url="https://tesl-staging.meshstream.io"
          />
          <BaseLink title="TESL - Dev" url="https://tesl-test.meshstream.io" />
          <BaseLink
            title="TESL - Dev(en)"
            url="https://tesl-test-en.meshstream.io"
          />
          <BaseLink
            title="TESL - Tw2(en)"
            url="https://tesl-en-tw2.meshstream.io"
          />
        </section>
        <section>
          <BaseLink
            title="Mesh Conference - cloud"
            url="https://conference-demo.meshstream.io/"
          />
          <BaseLink
            title="Mesh Conference - office"
            url="https://conference-office.meshstream.io/"
          />
          <BaseLink
            title="Mesh Conference - tw2"
            url="https://conference-tw2.meshstream.io/"
          />
        </section>
        <section>
          <BaseLink title="Mesh Map" url="https://map.meshstream.io/" />
        </section>
        <section>
          <BaseLink
            title="Mesh Transcoder"
            url="https://transcoder.meshstream.io/"
          />
        </section>
        <section>
          <BaseLink
            title="Ipcam 74"
            url="https://ntpc-nfn01.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 75"
            url="https://ntpc-nfn02.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 76"
            url="https://ntpc-nfn03.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 77"
            url="https://ntpc-nfn04.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 78"
            url="https://ntpc-nfn05.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 79"
            url="https://ntpc-nfn06.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 80"
            url="https://ntpc-nfn07.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="Ipcam 81"
            url="https://ntpc-nfn08.f2fcloud.com/health/report.html"
          />
          <BaseLink
            title="CHT POC"
            url="https://chtpoc.meshstream.io/?ipcam=true&roomId=CHTpoc"
          />
        </section>
        <section>
          <BaseLink
            title="Mesh NVR"
            url="https://streaming-admin.meshstream.io/ainvr/nvr/"
          />
        </section>
        <section>
          <BaseLink title="IPFS-Node" url="https://ipfs-webui.meshstream.io/" />
        </section>
      </main>
    </div>
  );
};

export default V1Home;
