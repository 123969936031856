import { addIcon } from '@iconify/react/offline';

import outlinePublic from '@iconify-icons/ic/outline-public';
import roundHomeRepairService from '@iconify-icons/ic/round-home-repair-service';
import baselineSocialDistance from '@iconify-icons/ic/baseline-social-distance';
import roundGroups from '@iconify-icons/ic/round-groups';
import outlineHub from '@iconify-icons/ic/outline-hub';
import baselineVideoSettings from '@iconify-icons/ic/baseline-video-settings';
import roundMovieFilter from '@iconify-icons/ic/round-movie-filter';
import baselineMenuOpen from '@iconify-icons/ic/baseline-menu-open';
import roundMenu from '@iconify-icons/ic/round-menu';
import outlineLocationOn from '@iconify-icons/ic/outline-location-on';
import outlineCloud from '@iconify-icons/ic/outline-cloud';
import outlineRouter from '@iconify-icons/ic/outline-router';

addIcon('ic:outline-public', outlinePublic);
addIcon('ic:round-home-repair-service', roundHomeRepairService);
addIcon('ic:baseline-social-distance', baselineSocialDistance);
addIcon('ic:round-groups', roundGroups);
addIcon('ic:outline-hub', outlineHub);
addIcon('ic:baseline-video-settings', baselineVideoSettings);
addIcon('ic:round-movie-filter', roundMovieFilter);
addIcon('ic:baseline-menu-open', baselineMenuOpen);
addIcon('ic:round-menu', roundMenu);
addIcon('ic:outline-location-on', outlineLocationOn);
addIcon('ic:outline-cloud', outlineCloud);
addIcon('ic:outline-router', outlineRouter);

/**
 * @example
 * import { Icon } from '@/shared/components';
 *
 * <Icon icon="ic:twotone-plus" />
 */
