import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './plugins';
// import './virtual:windi-base.css';
import './virtual:windi-components.css';
import './virtual:windi-utilities.css';

import './index.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
