import './Iframe.scss';

function Iframe({ url }) {
  return (
    <iframe
      src={url}
      title="demo"
      allow="camera;microphone;display-capture;fullscreen"
    />
  );
}

export default Iframe;
