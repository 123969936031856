import { ConfigProvider } from 'antd';
import zhTW from 'antd/es/locale/zh_TW';
import Routes from '@/routes';

import './App.module.scss';

const App = () => {
  return (
    <ConfigProvider locale={zhTW}>
      <div className="App">
        <Routes />
      </div>
    </ConfigProvider>
  );
};

export default App;
